// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Customização da barra de rolagem para um estilo mais moderno */
.maincontent_mainContent__PkLPp::-webkit-scrollbar {
    width: 12px; /* Largura da barra de rolagem */
    
}

.maincontent_mainContent__PkLPp::-webkit-scrollbar-track {
    
    background: #2b2b2b; /* Cor do fundo da trilha */
    border-radius: 10px; /* Bordas arredondadas da trilha */
}

.maincontent_mainContent__PkLPp::-webkit-scrollbar-thumb {
    background-color: var(--primary-color); /* Cor da barra de rolagem */
    border-radius: 10px; /* Bordas arredondadas da barra */
    border: 3px solid #2b2b2b; /* Cria espaço entre a barra e a trilha */
}

.maincontent_mainContent__PkLPp::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary-color); /* Cor ao passar o mouse sobre a barra */
}

/* Garantir que o conteúdo role quando necessário */
.maincontent_mainContent__PkLPp {
    flex-grow: 1;
    overflow-y: auto; /* Permitir rolagem vertical no conteúdo */
    height: 100vh; /* Ocupa a altura da viewport */
    
}

/* Garantir que o Sidebar não tenha rolagem */
.maincontent_sidebar__s\\+\\+pi {
    overflow-y: hidden; /* Impedir rolagem no sidebar */
    
}
`, "",{"version":3,"sources":["webpack://./src/components/MainContent/maincontent.module.css"],"names":[],"mappings":"AAAA,iEAAiE;AACjE;IACI,WAAW,EAAE,gCAAgC;;AAEjD;;AAEA;;IAEI,mBAAmB,EAAE,2BAA2B;IAChD,mBAAmB,EAAE,kCAAkC;AAC3D;;AAEA;IACI,sCAAsC,EAAE,4BAA4B;IACpE,mBAAmB,EAAE,iCAAiC;IACtD,yBAAyB,EAAE,yCAAyC;AACxE;;AAEA;IACI,wCAAwC,EAAE,wCAAwC;AACtF;;AAEA,mDAAmD;AACnD;IACI,YAAY;IACZ,gBAAgB,EAAE,0CAA0C;IAC5D,aAAa,EAAE,+BAA+B;;AAElD;;AAEA,6CAA6C;AAC7C;IACI,kBAAkB,EAAE,+BAA+B;;AAEvD","sourcesContent":["/* Customização da barra de rolagem para um estilo mais moderno */\n.mainContent::-webkit-scrollbar {\n    width: 12px; /* Largura da barra de rolagem */\n    \n}\n\n.mainContent::-webkit-scrollbar-track {\n    \n    background: #2b2b2b; /* Cor do fundo da trilha */\n    border-radius: 10px; /* Bordas arredondadas da trilha */\n}\n\n.mainContent::-webkit-scrollbar-thumb {\n    background-color: var(--primary-color); /* Cor da barra de rolagem */\n    border-radius: 10px; /* Bordas arredondadas da barra */\n    border: 3px solid #2b2b2b; /* Cria espaço entre a barra e a trilha */\n}\n\n.mainContent::-webkit-scrollbar-thumb:hover {\n    background-color: var(--secondary-color); /* Cor ao passar o mouse sobre a barra */\n}\n\n/* Garantir que o conteúdo role quando necessário */\n.mainContent {\n    flex-grow: 1;\n    overflow-y: auto; /* Permitir rolagem vertical no conteúdo */\n    height: 100vh; /* Ocupa a altura da viewport */\n    \n}\n\n/* Garantir que o Sidebar não tenha rolagem */\n.sidebar {\n    overflow-y: hidden; /* Impedir rolagem no sidebar */\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": `maincontent_mainContent__PkLPp`,
	"sidebar": `maincontent_sidebar__s++pi`
};
export default ___CSS_LOADER_EXPORT___;
