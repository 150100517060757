// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Blured_visibilityToggle__9yYcq {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
  }
  
  .Blured_visibilityToggle__9yYcq div {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: var(--text-color, #333); /* Cor do texto, substitua var(--text-color) pela cor desejada */
    font-weight: 100; /* Define a espessura da fonte */
    font-size: 0.9em; /* Ajusta o tamanho da fonte */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Blured/Blured.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,eAAe;IACf,8BAA8B,EAAE,gEAAgE;IAChG,gBAAgB,EAAE,gCAAgC;IAClD,gBAAgB,EAAE,8BAA8B;EAClD","sourcesContent":[".visibilityToggle {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    cursor: pointer;\n  }\n  \n  .visibilityToggle div {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    cursor: pointer;\n    color: var(--text-color, #333); /* Cor do texto, substitua var(--text-color) pela cor desejada */\n    font-weight: 100; /* Define a espessura da fonte */\n    font-size: 0.9em; /* Ajusta o tamanho da fonte */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visibilityToggle": `Blured_visibilityToggle__9yYcq`
};
export default ___CSS_LOADER_EXPORT___;
