// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogFilter_logRow__Kkb7E {
  transition: background-color 0.3s ease;
}

.LogFilter_logRow__Kkb7E:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

/* Pontos de Status */
.LogFilter_statusDot__C06TH {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.LogFilter_statusDot__C06TH.LogFilter_success__lQAdO {
  background-color: #4caf50; /* Verde para sucesso */
}

.LogFilter_statusDot__C06TH.LogFilter_pending__-DAi4 {
  background-color: #f39c12; /* Laranja para pendente */
}

.LogFilter_statusDot__C06TH.LogFilter_error__uYiFQ {
  background-color: #e74c3c; /* Vermelho para erro */
}
`, "",{"version":3,"sources":["webpack://./src/pages/LogPage/LogFilter.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;EACpC,eAAe;AACjB;;AAEA,qBAAqB;AACrB;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;AACpD;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;AACvD;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;AACpD","sourcesContent":[".logRow {\n  transition: background-color 0.3s ease;\n}\n\n.logRow:hover {\n  background-color: var(--hover-color);\n  cursor: pointer;\n}\n\n/* Pontos de Status */\n.statusDot {\n  display: inline-block;\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  margin-right: 8px;\n}\n\n.statusDot.success {\n  background-color: #4caf50; /* Verde para sucesso */\n}\n\n.statusDot.pending {\n  background-color: #f39c12; /* Laranja para pendente */\n}\n\n.statusDot.error {\n  background-color: #e74c3c; /* Vermelho para erro */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logRow": `LogFilter_logRow__Kkb7E`,
	"statusDot": `LogFilter_statusDot__C06TH`,
	"success": `LogFilter_success__lQAdO`,
	"pending": `LogFilter_pending__-DAi4`,
	"error": `LogFilter_error__uYiFQ`
};
export default ___CSS_LOADER_EXPORT___;
