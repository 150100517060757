const productsList = [
  {
    sku: 'SKU123',
    nome: 'Caixa de Areia para Gatos',
    imagem: 'https://via.placeholder.com/100',
  },
  {
    sku: 'SKU456',
    nome: 'Produto B',
    imagem: 'https://via.placeholder.com/100',
  },
  {
    sku: 'SKU789',
    nome: 'Produto C',
    imagem: 'https://via.placeholder.com/100',
  },
  {
    sku: 'SKU101',
    nome: 'Produto D',
    imagem: 'https://via.placeholder.com/100',
  },
  {
    sku: 'SKU102',
    nome: 'Produto E',
    imagem: 'https://via.placeholder.com/100',
  },
  {
    sku: 'SKU103',
    nome: 'Produto F',
    imagem: 'https://via.placeholder.com/100',
  },
  {
    sku: 'SKU104',
    nome: 'Produto G',
    imagem: 'https://via.placeholder.com/100',
  },
  {
    sku: 'SKU105',
    nome: 'Produto H',
    imagem: 'https://via.placeholder.com/100',
  },
  {
    sku: 'SKU106',
    nome: 'Produto I',
    imagem: 'https://via.placeholder.com/100',
  }
];

export default productsList;
