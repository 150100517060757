// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AIToolsPage_categoriaButtons__chrGl {
    display: flex;
    justify-content: center;
    gap: 20px; /* Aumentei o gap para coincidir com os outros botões */
    margin-bottom: 20px;
}

.AIToolsPage_categoriaButton__6f-iT {
    height: 45px; /* Define uma altura fixa */
    padding: 12px 20px; /* Ajuste para coincidir com outros botões */
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: auto;
    min-width: 120px;
}




.AIToolsPage_categoriaButton__6f-iT:hover {
    background: linear-gradient(135deg, #00d9ff, var(--primary-color));
    color: white;
}

.AIToolsPage_categoriaAtiva__WNgLx {
    background: linear-gradient(135deg, #00d9ff, var(--primary-color));
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/AIToolsPage/AIToolsPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS,EAAE,uDAAuD;IAClE,mBAAmB;AACvB;;AAEA;IACI,YAAY,EAAE,2BAA2B;IACzC,kBAAkB,EAAE,4CAA4C;IAChE,kBAAkB;IAClB,wCAAwC;IACxC,wBAAwB;IACxB,eAAe;IACf,eAAe;IACf,6CAA6C;IAC7C,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB;;;;;AAKA;IACI,kEAAkE;IAClE,YAAY;AAChB;;AAEA;IACI,kEAAkE;IAClE,YAAY;AAChB","sourcesContent":[".categoriaButtons {\n    display: flex;\n    justify-content: center;\n    gap: 20px; /* Aumentei o gap para coincidir com os outros botões */\n    margin-bottom: 20px;\n}\n\n.categoriaButton {\n    height: 45px; /* Define uma altura fixa */\n    padding: 12px 20px; /* Ajuste para coincidir com outros botões */\n    border-radius: 5px;\n    background-color: var(--secondary-color);\n    color: var(--text-color);\n    font-size: 14px;\n    cursor: pointer;\n    transition: background-color 0.3s, color 0.3s;\n    border: none;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-weight: bold;\n    width: auto;\n    min-width: 120px;\n}\n\n\n\n\n.categoriaButton:hover {\n    background: linear-gradient(135deg, #00d9ff, var(--primary-color));\n    color: white;\n}\n\n.categoriaAtiva {\n    background: linear-gradient(135deg, #00d9ff, var(--primary-color));\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoriaButtons": `AIToolsPage_categoriaButtons__chrGl`,
	"categoriaButton": `AIToolsPage_categoriaButton__6f-iT`,
	"categoriaAtiva": `AIToolsPage_categoriaAtiva__WNgLx`
};
export default ___CSS_LOADER_EXPORT___;
