// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TokensBuyPage_main-content__yJu\\+6 {
  padding: 20px; /* Ajuste conforme necessário */
}

.TokensBuyPage_title__Jn3h1 {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 30px;
  font-size: 24px;
}

/* Estilos para os botões de parcelamento */
.TokensBuyPage_paymentPlans__WM0Kv {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.TokensBuyPage_paymentButton__5qLGk {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: #202123; /* Cor de fundo padrão */
  color: var(--text-color); /* Cor do texto padrão */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Transição suave para cor de fundo e texto */
}

.TokensBuyPage_paymentButton__5qLGk.TokensBuyPage_active__FPTe6 {
  background-image: linear-gradient(135deg, #00d9ff, var(--primary-color)); /* Gradiente de cor para botão ativo */
  color: white; /* Cor do texto quando ativo */
}

.TokensBuyPage_paymentButton__5qLGk:hover {
  background-image: linear-gradient(135deg, #00d9ff, var(--primary-color)); /* Gradiente de cor ao passar o mouse */
  color: white; /* Cor do texto ao passar o mouse */
}
`, "",{"version":3,"sources":["webpack://./src/pages/TokensBuyPage/TokensBuyPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,+BAA+B;AAChD;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,mBAAmB;EACnB,eAAe;AACjB;;AAEA,2CAA2C;AAC3C;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB,EAAE,wBAAwB;EACnD,wBAAwB,EAAE,wBAAwB;EAClD,eAAe;EACf,6CAA6C,EAAE,8CAA8C;AAC/F;;AAEA;EACE,wEAAwE,EAAE,sCAAsC;EAChH,YAAY,EAAE,8BAA8B;AAC9C;;AAEA;EACE,wEAAwE,EAAE,uCAAuC;EACjH,YAAY,EAAE,mCAAmC;AACnD","sourcesContent":[".main-content {\n  padding: 20px; /* Ajuste conforme necessário */\n}\n\n.title {\n  text-align: center;\n  color: var(--text-color);\n  margin-bottom: 30px;\n  font-size: 24px;\n}\n\n/* Estilos para os botões de parcelamento */\n.paymentPlans {\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n  margin-bottom: 20px;\n}\n\n.paymentButton {\n  padding: 10px 15px;\n  border-radius: 5px;\n  border: none;\n  background-color: #202123; /* Cor de fundo padrão */\n  color: var(--text-color); /* Cor do texto padrão */\n  cursor: pointer;\n  transition: background-color 0.3s, color 0.3s; /* Transição suave para cor de fundo e texto */\n}\n\n.paymentButton.active {\n  background-image: linear-gradient(135deg, #00d9ff, var(--primary-color)); /* Gradiente de cor para botão ativo */\n  color: white; /* Cor do texto quando ativo */\n}\n\n.paymentButton:hover {\n  background-image: linear-gradient(135deg, #00d9ff, var(--primary-color)); /* Gradiente de cor ao passar o mouse */\n  color: white; /* Cor do texto ao passar o mouse */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-content": `TokensBuyPage_main-content__yJu+6`,
	"title": `TokensBuyPage_title__Jn3h1`,
	"paymentPlans": `TokensBuyPage_paymentPlans__WM0Kv`,
	"paymentButton": `TokensBuyPage_paymentButton__5qLGk`,
	"active": `TokensBuyPage_active__FPTe6`
};
export default ___CSS_LOADER_EXPORT___;
