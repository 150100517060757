import LoginForm from "../../components/LoginForm/LoginForm"

const SignIn = () => {
  return (
    <>
      <LoginForm />
    </>
  )
}

export default SignIn