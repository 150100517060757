// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container das folhas */
.FallingLeaves_fallingLeaves__Ssg97 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  perspective: 400px; /* Define a profundidade para o efeito 3D */
}

/* Estilos das folhas */
.FallingLeaves_leaf__Kp5t- {
  position: absolute;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  transform-origin: center;
  transform-style: preserve-3d; /* Mantém o efeito tridimensional */
  backface-visibility: visible; /* Permite que ambos os lados da folha sejam visíveis */
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/FallingLeaves/FallingLeaves.module.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB,EAAE,2CAA2C;AACjE;;AAEA,uBAAuB;AACvB;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,4BAA4B;EAC5B,wBAAwB;EACxB,4BAA4B,EAAE,mCAAmC;EACjE,4BAA4B,EAAE,uDAAuD;EACrF,oBAAoB;AACtB","sourcesContent":["/* Container das folhas */\n.fallingLeaves {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  pointer-events: none;\n  perspective: 400px; /* Define a profundidade para o efeito 3D */\n}\n\n/* Estilos das folhas */\n.leaf {\n  position: absolute;\n  width: 30px;\n  height: 30px;\n  background-size: contain;\n  background-repeat: no-repeat;\n  transform-origin: center;\n  transform-style: preserve-3d; /* Mantém o efeito tridimensional */\n  backface-visibility: visible; /* Permite que ambos os lados da folha sejam visíveis */\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fallingLeaves": `FallingLeaves_fallingLeaves__Ssg97`,
	"leaf": `FallingLeaves_leaf__Kp5t-`
};
export default ___CSS_LOADER_EXPORT___;
