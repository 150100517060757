// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChartFilter.module.css */

.ChartFilter_filterContainer__N0EZJ {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Posiciona o filtro no canto superior direito */
  position: absolute; /* Define posição absoluta dentro do container pai */
  top: 0; /* Coloca no topo */
  right: 0; /* Coloca à direita */
  gap: 10px;
  margin: 10px; /* Ajusta a margem para que não fique encostado nas bordas */
  z-index: 10; /* Garante que esteja acima dos demais elementos, se necessário */
}

.ChartFilter_filterWrapper__SAZhB {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ChartFilter_filterContainer__N0EZJ label {
  font-weight: bold;
  margin-right: 5px;
  font-size: 1em;
}

.ChartFilter_select__t2rpo {
  padding: 5px;
  border-radius: 4px;
  font-size: 1em;
  border: 1px solid var(--border-color);
  background-color: var(--text-color); /* Fundo consistente com o tema */
  color: var(--secondary-color); /* Cor do texto */
}

/* Responsividade */
@media (max-width: 768px) {
  .ChartFilter_filterContainer__N0EZJ {
    position: static; /* Reseta a posição em telas menores */
    flex-direction: column;
    align-items: flex-start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ChartSection/ChartFilter.module.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB,EAAE,iDAAiD;EAC5E,kBAAkB,EAAE,oDAAoD;EACxE,MAAM,EAAE,mBAAmB;EAC3B,QAAQ,EAAE,qBAAqB;EAC/B,SAAS;EACT,YAAY,EAAE,4DAA4D;EAC1E,WAAW,EAAE,iEAAiE;AAChF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,qCAAqC;EACrC,mCAAmC,EAAE,iCAAiC;EACtE,6BAA6B,EAAE,iBAAiB;AAClD;;AAEA,mBAAmB;AACnB;EACE;IACE,gBAAgB,EAAE,sCAAsC;IACxD,sBAAsB;IACtB,uBAAuB;EACzB;AACF","sourcesContent":["/* ChartFilter.module.css */\n\n.filterContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end; /* Posiciona o filtro no canto superior direito */\n  position: absolute; /* Define posição absoluta dentro do container pai */\n  top: 0; /* Coloca no topo */\n  right: 0; /* Coloca à direita */\n  gap: 10px;\n  margin: 10px; /* Ajusta a margem para que não fique encostado nas bordas */\n  z-index: 10; /* Garante que esteja acima dos demais elementos, se necessário */\n}\n\n.filterWrapper {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.filterContainer label {\n  font-weight: bold;\n  margin-right: 5px;\n  font-size: 1em;\n}\n\n.select {\n  padding: 5px;\n  border-radius: 4px;\n  font-size: 1em;\n  border: 1px solid var(--border-color);\n  background-color: var(--text-color); /* Fundo consistente com o tema */\n  color: var(--secondary-color); /* Cor do texto */\n}\n\n/* Responsividade */\n@media (max-width: 768px) {\n  .filterContainer {\n    position: static; /* Reseta a posição em telas menores */\n    flex-direction: column;\n    align-items: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": `ChartFilter_filterContainer__N0EZJ`,
	"filterWrapper": `ChartFilter_filterWrapper__SAZhB`,
	"select": `ChartFilter_select__t2rpo`
};
export default ___CSS_LOADER_EXPORT___;
