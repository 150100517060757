import React from 'react';
import styles from './stores.module.css';

const PerformanceContent = () => (
  <div>
    <h2>Desempenho da Loja</h2>
    <div className={styles.performanceCharts}>
      {/* Espaço para gráficos de desempenho */}
    </div>
  </div>
);

export default PerformanceContent;
