import RegisterForm from "../../components/RegisterForm/RegisterForm"

const SignUp = () => {
  return (
    <>
      <RegisterForm />
    </>
  )
}

export default SignUp